import React, {useRef, useState} from "react";
import {PageTitle, Section, SectionContainer, SectionSubTitle} from "../assets/components/section";
import Grid from "@mui/material/Unstable_Grid2";
import {Form, InputLabel, JSCheckbox, JSFormControlLabel, JYTextField, Required} from "../assets/components/form";
import RadioButtonCheckedOutlinedIcon from '@mui/icons-material/RadioButtonCheckedOutlined';
import RadioButtonUncheckedOutlinedIcon from '@mui/icons-material/RadioButtonUncheckedOutlined';
import Button from "../components/Button";
import {useNavigate} from "react-router-dom";
import {Fade} from 'react-reveal';
import emailjs from '@emailjs/browser';
import {useTheme} from "styled-components";
import {Alert, Backdrop, CircularProgress, Snackbar} from '@mui/material';
import {emailJS} from "../constants/constant";

const DEFAULT_FORM = {
  company_name: '',
  name: '',
  email: '',
  phone: '',
  quantity: '',
  price: '',
  message: '',
  agree: false,
};

function Contact() {
  const navigate = useNavigate();
  const formRef = useRef();
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [form, setForm] = useState(DEFAULT_FORM);

  const sendEmail = (e) => {
    e.preventDefault();

    setLoading(true);

    emailjs.sendForm(emailJS.serviceID, emailJS.templateID, formRef.current, emailJS.publicKey)
      .then((result) => {
        setLoading(false);
        formRef.current.reset();

        setOpenSnackbar(true);
        setForm(DEFAULT_FORM);
      }, (error) => {
        console.log(error.text);
        setLoading(false);
      });
  };

  function closeSnackbar(event, reason) {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackbar(false);
  };

  function handleChange(e) {
    setForm({
      ...form,
      [e.target.name]: e.target.value
    });
  }

  return (
    <SectionContainer marginRight={88} noCover={true}>
      <Section>
        <Snackbar
          open={openSnackbar}
          autoHideDuration={2000}
          onClose={closeSnackbar}
          anchorOrigin={{vertical: 'top', horizontal: 'center'}}
        >
          <Alert onClose={closeSnackbar} sx={{width: '100%'}}>
            문의가 완료되었습니다.
          </Alert>
        </Snackbar>
        <Backdrop
          sx={{color: theme.colors.white, zIndex: 100}}
          open={loading}
        >
          <CircularProgress color="inherit"/>
        </Backdrop>
        <Grid spacing={{xs: 0, sm: 0, md: 12}} container>
          <Grid xs={12} sm={12} md={6}>
            <Fade duration={2000} top>
              <PageTitle>
                Contact Us
              </PageTitle>
              <SectionSubTitle marginTop={20} mobileMarginTop={20}>
                저희 조선양봉은 여러분의 질문에 답변하고 더 많은 정보를 제공하며 도움을 드리기 위해 준비되어 있습니다.
                <br/><br/>문의 남겨주시면 담당자가 확인 즉시 연락드리고 있습니다.
              </SectionSubTitle>
            </Fade>
          </Grid>
          <Grid xs={12} sm={12} md={6}>
            <Form ref={formRef} onSubmit={sendEmail}>
              <InputLabel>회사<Required>*</Required></InputLabel>
              <JYTextField name="company_name" onChange={handleChange} required fullWidth placeholder="회사명을 입력해주세요"/>
              <InputLabel>성명<Required>*</Required></InputLabel>
              <JYTextField name="name" onChange={handleChange} required fullWidth placeholder="담당자 성명을 입력해주세요"/>
              <InputLabel>E-mail<Required>*</Required></InputLabel>
              <JYTextField name="email" onChange={handleChange} required fullWidth placeholder="이메일을 입력해주세요"/>
              <InputLabel>전화번호<Required>*</Required></InputLabel>
              <JYTextField name="phone" onChange={handleChange} required fullWidth placeholder="‘-’는 빼고 숫자만 입력해주세요"/>
              <InputLabel>필요 수량</InputLabel>
              <JYTextField name="quantity" onChange={handleChange} fullWidth placeholder="필요 수량을 입력해주세요"/>
              <InputLabel>희망 단가</InputLabel>
              <JYTextField name="price" onChange={handleChange} fullWidth placeholder="희망 단가를 입력해주세요"/>
              <InputLabel>문의내용<Required>*</Required></InputLabel>
              <JYTextField required fullWidth multiline
                           name="message"
                           onChange={handleChange}
                           rows={4}
                           variant="outlined"
                           placeholder="내용을 입력해주세요"/>
              <JSFormControlLabel
                control={
                  <JSCheckbox required icon={<RadioButtonUncheckedOutlinedIcon/>}
                              checkedIcon={<RadioButtonCheckedOutlinedIcon/>}
                              checked={form.agree}
                              onChange={(e) => setForm({...form, agree: e.target.checked})}

                  />}
                label={<>개인정보취급방침에 동의합니다 <a onClick={() => navigate('/privacy')}>[약관보기]</a></>}/>
              <Button type="submit">
                문의하기
              </Button>
            </Form>
          </Grid>
        </Grid>
      </Section>
    </SectionContainer>
  )
}

export default Contact;
